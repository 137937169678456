/**
 * Type for method injected into `Input.vue`. Called when `Input` is mounted
 *
 * @param element: Reference to input element
 * @param name: Optional value, useful for differentiating multiple elements. Passes `name` from `Input` props.
 */
export type InputFieldSetterFunction = (
  element: HTMLInputElement,
  name?: string
) => void
export const InputFieldInjectionKey =
  Symbol() as InjectionKey<InputFieldSetterFunction>
